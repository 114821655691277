/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { EuiFieldNumber, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSwitch } from '@elastic/eui'

import {
  DEFAULT_DEFAULT_RETENTION_DAYS,
  MAX_DEFAULT_RETENTION_DAYS,
  MIN_DEFAULT_RETENTION_DAYS,
} from '@modules/project-lib/dataRetention'
import { useToggle } from '@modules/utils/hooks/useToggle'

import type { FC } from 'react'

const messages = defineMessages({
  sectionDescription: {
    id: `defaultDataRetentionPeriodSection.sectionDescription`,
    defaultMessage: `Define a retention period that will automatically be applied to all data streams that do not have a custom retention period already set.`,
  },
  switchLabel: {
    id: `defaultDataRetentionPeriodSection.switchLabel`,
    defaultMessage: `Enable default data retention period`,
  },
  retentionPeriodLabel: {
    id: `defaultDataRetentionPeriodSection.retentionPeriodLabel`,
    defaultMessage: `Default data retention period`,
  },
  retentionPeriodUnit: {
    id: `defaultDataRetentionPeriodSection.retentionPeriodUnit`,
    defaultMessage: `days`,
  },
  invalidDefaultDataMaxRetentionPeriodErrorText: {
    id: `defaultDataRetentionPeriodSection.defaultDataMaxRetentionPeriodHelpText`,
    defaultMessage: `The default data retention period cannot be longer than the maximum data retention period.`,
  },
})

type DefaultDataRetentionPeriodSectionProps = {
  onChange: (value: number) => void
  value: number
  error?: string
  helpText: string
}

const DefaultDataRetentionPeriodSection: FC<DefaultDataRetentionPeriodSectionProps> = ({
  onChange,
  value,
  error,
  helpText,
}) => {
  const { formatMessage } = useIntl()

  const [isSwitchChecked, toggleSwitchChecked] = useToggle(Number.isFinite(value))

  return (
    <EuiFlexGroup direction='column' gutterSize='l'>
      <EuiFlexItem>
        <EuiSwitch
          label={formatMessage(messages.switchLabel)}
          checked={isSwitchChecked}
          onChange={() => {
            onChange(isSwitchChecked ? Infinity : DEFAULT_DEFAULT_RETENTION_DAYS)

            toggleSwitchChecked()
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <p>{formatMessage(messages.sectionDescription)}</p>
      </EuiFlexItem>
      {isSwitchChecked && (
        <EuiFlexItem>
          <EuiFormRow
            label={formatMessage(messages.retentionPeriodLabel)}
            helpText={helpText}
            isInvalid={error !== undefined}
            error={error}
          >
            <EuiFieldNumber
              min={MIN_DEFAULT_RETENTION_DAYS}
              max={MAX_DEFAULT_RETENTION_DAYS}
              append={formatMessage(messages.retentionPeriodUnit)}
              step={1}
              value={Number.isInteger(value) ? (value as number) : ''}
              onChange={(e) => onChange(parseInt(e.target.value, 10))}
              fullWidth={true}
            />
          </EuiFormRow>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default DefaultDataRetentionPeriodSection
