/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useRef } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiSplitPanel,
  withEuiTheme,
  type WithEuiThemeProps,
} from '@elastic/eui'
import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
  type PointerEvent,
} from '@elastic/charts'

import { MetricTitle } from '@modules/auto-ops-components/MetricTitle/MetricTitle'
import type { MetricItem } from '@modules/autoops-api/types'

type DefaultChartsProps = WithEuiThemeProps & { data: Record<string, MetricItem | undefined> }

const baseTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

const DefaultCharts = ({ data, theme }: DefaultChartsProps) => {
  const { ingest, storage } = data
  const hasIngest = ingest && 'metrics' in ingest
  const hasStorage = storage && 'metrics' in storage
  const refIngest = useRef<Chart>(null)
  const refStorage = useRef<Chart>(null)

  const pointerUpdate = (event: PointerEvent) => {
    refIngest.current?.dispatchExternalPointerEvent(event)
    refStorage.current?.dispatchExternalPointerEvent(event)
  }

  if (!hasIngest && !hasStorage) {
    return null
  }

  return (
    <EuiFlexGroup gutterSize='l'>
      {hasIngest && (
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='Ingest rate'
                infoContent='Amount of data ingested per unit of time.'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refIngest}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  tickFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis
                  id='left'
                  position={Position.Left}
                  tickFormat={(d) => `${Number(d).toFixed(2)}`}
                />

                {ingest.metrics.map((metricItem) => (
                  <LineSeries
                    key={metricItem.name}
                    id={metricItem.name}
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={metricItem.data}
                  />
                ))}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      )}
      {hasStorage && (
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='Storage retained'
                infoContent='Amount of data that is being retained in long-term object storage (subject to data retention settings).'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refStorage}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  tickFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis id='left' position={Position.Left} tickFormat={(d) => `${d} GB`} />

                {storage.metrics.map((metricItem) => (
                  <AreaSeries
                    key={metricItem.name}
                    id={metricItem.name}
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={metricItem.data}
                  />
                ))}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default withEuiTheme(DefaultCharts)
