/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useQuery } from 'react-query'

import datemath from '@elastic/datemath'

import { getProjectMetrics } from '@modules/autoops-api'
import { STATS_BY_PROJECT_TYPE } from '@modules/autoops-lib/constants'
import type { ProjectType } from '@modules/ui-types/projects'

const isSearch = ({ metricName }) => metricName === 'PROJECT_QUERY_RATE_AND_LATENCY'
const isVCU = ({ metricName }) => metricName === 'PROJECT_VCU_USAGE'
const isStorage = ({ metricName }) => metricName === 'PROJECT_STORAGE_RETAINED'
const isIngest = ({ metricName }) => metricName === 'PROJECT_INGEST_RATE'

export const useGetProjectMetricsQuery = ({
  projectId,
  projectType,
  organizationId,
  range,
}: {
  projectId: string
  projectType: ProjectType
  organizationId: string
  range: { from: string; to: string }
}) =>
  useQuery(
    ['getAutoOpsMetrics', projectId, range.from, range.to],
    () => {
      const stats = STATS_BY_PROJECT_TYPE[projectType]

      const timeRange = {
        from: datemath.parse(range.from)?.valueOf() ?? 0,
        to: datemath.parse(range.to, { roundUp: true })?.valueOf() ?? 0,
      }

      return getProjectMetrics({
        projectId,
        organizationId,
        timeRange,
        stats,
      })
    },
    {
      select: (data) => ({
        search: data.metricSeries.find(isSearch),
        VCU: data.metricSeries.find(isVCU),
        storage: data.metricSeries.find(isStorage),
        ingest: data.metricSeries.find(isIngest),
      }),
      cacheTime: 0,
      staleTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  )
