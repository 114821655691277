/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'

import { EuiSkeletonText } from '@elastic/eui'

import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { getSaasLatestTrial } from '@modules/profile-lib/trial'

import ProjectCosts from './ProjectCosts'

type Props = {
  saasUser: SaasUserResponse
}

const Costs: React.FunctionComponent<Props> = ({ saasUser }) => {
  const trial = getSaasLatestTrial(saasUser)

  const costsQuery = useGetCostsItemsV2({
    pathParameters: { organization_id: saasUser.user.organization_id },
    queryParameters: { from: trial?.start, to: trial?.end },
  })

  if (costsQuery.isLoading) {
    return <EuiSkeletonText />
  }

  const products = costsQuery.data?.products ?? []

  const projectProducts = products.filter((product) => product.type !== 'deployment')

  return (
    <Fragment>{projectProducts.length > 0 && <ProjectCosts products={projectProducts} />}</Fragment>
  )
}

export default Costs
