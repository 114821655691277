/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Formik } from 'formik'
import { jsx } from '@emotion/react'

import { EuiButton, EuiFlexGroup, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { getPemFormatCertificate } from '@modules/security-idp-lib'
import type { IdpConfiguration, IdpResponse } from '@modules/security-idp-api/types'
import { CuiFormField } from '@modules/cui/forms'
import { useOrganizationIdpMutation } from '@modules/security-idp-lib/hooks'
import { addToast } from '@modules/cui/Toasts'
import type { ApiErrorCollection } from '@modules/query/types'

import { EditUserAuthenticationFormRow } from '../components/EditUserAuthenticationFormRow'

import { validateUserInput } from './lib'
import { updateIdpConfigErrorMessages } from './messages'

import type { IdpUserInput } from '../types'
import type { ComponentType, ReactElement } from 'react'

const getUpdatedIdpConfiguration = (
  idpResponse: IdpResponse | undefined,
  values: IdpUserInput,
): IdpConfiguration => ({
  enabled: idpResponse?.configuration.enabled ?? true,
  login_identifier_prefix: values.loginIdentifierPrefix,
  saml_idp: {
    issuer: values.issuer,
    sso_url: values.ssoUrl,
    public_certificate: [Buffer.from(values.publicCertificate, 'utf-8').toString('base64')],
  },
})

export const UserProvidedConfiguration = ({
  organizationId,
  idpResponse,
}: {
  organizationId: string
  idpResponse: IdpResponse | undefined
}) => {
  const { formatMessage } = useIntl()
  const { mutate: updateOrganizationIdp, isLoading } = useOrganizationIdpMutation()

  const userConfigurationResponse = idpResponse?.configuration
  const savedPublicCertificate = userConfigurationResponse?.saml_idp.public_certificate[0]
  const initialFormValues: IdpUserInput = {
    loginIdentifierPrefix: userConfigurationResponse?.login_identifier_prefix || '',
    ssoUrl: userConfigurationResponse?.saml_idp.sso_url || '',
    issuer: userConfigurationResponse?.saml_idp.issuer || '',
    publicCertificate: getPemFormatCertificate(savedPublicCertificate || ''),
  }

  const onSubmit = (values) => {
    updateOrganizationIdp(
      {
        organizationId,
        idpConfiguration: getUpdatedIdpConfiguration(idpResponse, values),
      },
      {
        onSuccess: () => {
          addToast({
            family: 'organization.security.edit-user-authentication.updating-idp-toast',
            color: 'success',
            iconType: 'check',
            title: (
              <FormattedMessage
                id='organization.security.edit-user-authentication.success-updating-idp-toast'
                defaultMessage='Configuration updated'
              />
            ),
          })
        },
        onError: (error: ApiErrorCollection) => {
          const errorCode = error.errors.pop()?.code

          addToast({
            family: 'organization.security.edit-user-authentication.updating-idp-toast',
            color: 'danger',
            iconType: 'alert',
            title: formatMessage(
              updateIdpConfigErrorMessages[errorCode || ''] ||
                updateIdpConfigErrorMessages.unknownError,
            ),
          })
        },
      },
    )
  }

  return (
    <div>
      <EuiText color='subdued'>
        <FormattedMessage
          id='organization.security.user-authentication.provided-by-you'
          defaultMessage='PROVIDED BY YOU'
        />
      </EuiText>
      <EuiSpacer size='s' />
      <Formik
        initialValues={initialFormValues}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
        validate={validateUserInput(formatMessage)}
      >
        {({ dirty }) => (
          <Form>
            <Row
              label={
                <FormattedMessage
                  id='organization.security.user-authentication.idp-entity-id'
                  defaultMessage='Identity Provider Entity ID'
                />
              }
              name='issuer'
              description={
                <FormattedMessage
                  id='organization.security.user-authentication.idp-entity-id-description'
                  defaultMessage='The unique identifier of your identity provider that allows Elastic Cloud to verify the source of SAML assertions. This is the issuer your identity provider will send in SAML responses.'
                />
              }
            />
            <Row
              label={
                <FormattedMessage
                  id='organization.security.user-authentication.idp-sso-url'
                  defaultMessage='Identity Provider SSO URL'
                />
              }
              description={
                <FormattedMessage
                  id='organization.security.user-authentication.idp-sso-url-description'
                  defaultMessage='The URL from your identity provider that your users will be redirected to when logging in. This should be the HTTP-POST SAML binding of your identity provider.'
                />
              }
              name='ssoUrl'
            />
            <Row
              label={
                <FormattedMessage
                  id='organization.security.user-authentication.public-cert'
                  defaultMessage='Public x509 certificate'
                />
              }
              description={
                <FormattedMessage
                  id='organization.security.user-authentication.public-cert-description'
                  defaultMessage='This certificate is used to verify the authenticity and integrity of SAML assertions during the SSO process.'
                />
              }
              name='publicCertificate'
              component={EuiTextArea}
            />
            <Row
              label={
                <FormattedMessage
                  id='organization.security.user-authentication.login-id-prefix'
                  defaultMessage='Login identifier prefix'
                />
              }
              description={
                <FormattedMessage
                  id='organization.security.user-authentication.login-id-prefix-description'
                  defaultMessage='<p>The string used to differentiate your organization’s dedicated SSO login URL. For example, `cloud.elastic.co/sso/login/<b>myacmeorg</b>-12345`.</p>'
                  values={{ p: (content) => <p>{content}</p>, b: (content) => <b>{content}</b> }}
                />
              }
              name='loginIdentifierPrefix'
            />
            <EuiSpacer />
            <EuiFlexGroup justifyContent='flexEnd'>
              <EuiButton type='submit' color='primary' isLoading={isLoading} disabled={!dirty}>
                <FormattedMessage
                  id='organization.security.user-authentication.update-configuration'
                  defaultMessage='Update configuration'
                />
              </EuiButton>
            </EuiFlexGroup>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Row = ({
  label,
  name,
  description,
  component,
}: {
  label: ReactElement
  name: string
  description: ReactElement
  component?: ComponentType<any>
}) => (
  <EditUserAuthenticationFormRow
    label={label}
    valueElement={
      <CuiFormField
        name={name}
        component={component}
        fullWidth={true}
        helpText={description}
        aria-label={name}
      />
    }
  />
)
