/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { sumBy } from 'lodash'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiStat,
  EuiText,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { Product } from '@modules/billing-api/v1/types'

import Total from './Total'

type Props = {
  products: Product[]
}

const DATA_RETENTION_TYPES = ['Search AI Lake']

const ProjectCosts: React.FunctionComponent<Props> = ({ products }) => {
  const htmlId = useGeneratedHtmlId()

  const lineItems = products.flatMap((product) => product.product_line_items)

  const dataLineItems = lineItems.filter((item) => DATA_RETENTION_TYPES.includes(item.type))
  const vcuLineItems = lineItems.filter((item) => item.type === 'VCU')
  const dataOutLineItems = lineItems.filter((item) => item.type === 'PLACEHOLDER') // data out not being charged yet

  const dataAmount = sumBy(dataLineItems, (item) => item.quantity.value)
  const vcuAmount = sumBy(vcuLineItems, (item) => item.quantity.value)
  const dataOutAmount = sumBy(dataOutLineItems, (item) => item.quantity.value)

  const dataCost = sumBy(dataLineItems, (item) => item.total_ecu)
  const vcuCost = sumBy(vcuLineItems, (item) => item.total_ecu)
  const dataOutCost = sumBy(dataOutLineItems, (item) => item.total_ecu)

  const dataRate = dataAmount > 0 ? dataCost / dataAmount : 0
  const vcuRate = vcuAmount > 0 ? vcuCost / vcuAmount : 0
  const dataOutRate = dataOutAmount > 0 ? dataOutCost / dataOutAmount : 0

  const totalRate = dataRate + vcuRate + dataOutRate

  return (
    <EuiFlexGroup direction='column'>
      <EuiFlexItem grow={false}>
        <EuiPanel hasBorder={true}>
          <EuiFlexGroup justifyContent='spaceBetween' data-test-id='trial-summary-stats'>
            <EuiFlexItem grow={false}>
              <EuiStat
                titleSize='s'
                title={dataAmount.toFixed(0)}
                description={
                  <FormattedMessage
                    id='trialSummary.dataAmountLabel'
                    defaultMessage='GB of retained data'
                  />
                }
                reverse={true}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat
                titleSize='s'
                title={vcuAmount.toFixed(0)}
                description={
                  <FormattedMessage id='trialSummary.vcuAmountLabel' defaultMessage='Total VCU' />
                }
                reverse={true}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiStat
                titleSize='s'
                title={dataOutAmount.toFixed(0)}
                description={
                  <FormattedMessage
                    id='trialSummary.dataOutAmountLabel'
                    defaultMessage='Data out'
                  />
                }
                reverse={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiFlexItem>

      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction='column' gutterSize='m'>
          <EuiFlexItem>
            <EuiFlexGroup justifyContent='spaceBetween' role='row'>
              <EuiFlexItem>
                <EuiText role='cell' size='s'>
                  <strong id={`${htmlId}-retainedDataLabel`}>
                    <FormattedMessage
                      id='trialSummary.retainedDataLabel'
                      defaultMessage='Search AI Lake'
                    />
                  </strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  aria-describedby={`${htmlId}-retainedDataLabel`}
                >
                  {dataAmount.toFixed(0)} GB
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  role='cell'
                  size='s'
                  textAlign='right'
                  aria-describedby={`${htmlId}-retainedDataLabel`}
                >
                  <strong>${dataCost.toFixed(2)}</strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  textAlign='right'
                  aria-describedby={`${htmlId}-retainedDataLabel`}
                >
                  ${dataRate.toFixed(4)} / hour
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup justifyContent='spaceBetween' role='row'>
              <EuiFlexItem>
                <EuiText role='cell' size='s'>
                  <strong id={`${htmlId}-totalVcuLabel`}>
                    <FormattedMessage id='trialSummary.totalVcuLabel' defaultMessage='Total VCU' />
                  </strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  aria-describedby={`${htmlId}-totalVcuLabel`}
                >
                  {vcuAmount.toFixed(0)}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  role='cell'
                  size='s'
                  textAlign='right'
                  aria-describedby={`${htmlId}-totalVcuLabel`}
                >
                  <strong>${vcuCost.toFixed(2)}</strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  textAlign='right'
                  aria-describedby={`${htmlId}-totalVcuLabel`}
                >
                  ${vcuRate.toFixed(4)} / hour
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup justifyContent='spaceBetween' role='row'>
              <EuiFlexItem>
                <EuiText role='cell' size='s'>
                  <strong id={`${htmlId}-dataOutlabel`}>
                    <FormattedMessage id='trialSummary.dataOutlabel' defaultMessage='Data out' />
                  </strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  aria-describedby={`${htmlId}-dataOutlabel`}
                >
                  {dataOutAmount.toFixed(0)} GB
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText
                  role='cell'
                  size='s'
                  textAlign='right'
                  aria-describedby={`${htmlId}-dataOutlabel`}
                >
                  <strong>${dataOutCost.toFixed(2)}</strong>
                </EuiText>
                <EuiText
                  role='cell'
                  size='s'
                  color='subdued'
                  textAlign='right'
                  aria-describedby={`${htmlId}-dataOutlabel`}
                >
                  ${dataOutRate.toFixed(4)} / hour
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiHorizontalRule margin='m' />
        <Total hourlyRate={totalRate} />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default ProjectCosts
