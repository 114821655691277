/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useRef } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiSplitPanel,
  withEuiTheme,
  type WithEuiThemeProps,
} from '@elastic/eui'
import {
  AreaSeries,
  Axis,
  Chart,
  DARK_THEME,
  LIGHT_THEME,
  LineSeries,
  Position,
  ScaleType,
  Settings,
  timeFormatter,
  Tooltip,
  type PointerEvent,
} from '@elastic/charts'

import { MetricTitle } from '@modules/auto-ops-components/MetricTitle/MetricTitle'
import type { Metric, MetricItem } from '@modules/autoops-api/types'

const baseTheme = {
  LIGHT: LIGHT_THEME,
  DARK: DARK_THEME,
}

type ElasticsearchChartsProps = WithEuiThemeProps & { data: Record<string, MetricItem | undefined> }

const yAxisStyle = {
  tickLine: { visible: true, size: 0, padding: 10 },
  tickLabel: {
    alignment: {
      horizontal: Position.Left,
      vertical: Position.Bottom,
    },
    padding: 0,
    offset: { x: 0, y: 0 },
  },
}

const calculateDomain = (item: Metric | undefined) => {
  if (!item) {
    return { min: 0, max: 0 }
  }

  const values = item.data.map((unit) => unit[1])

  return { min: Math.min(...values), max: Math.max(...values) }
}

const ElasticsearchCharts = ({ data, theme }: ElasticsearchChartsProps) => {
  const { search, VCU, storage } = data
  const hasSearch = search && 'metrics' in search
  const hasVCU = VCU && 'metrics' in VCU
  const hasStorage = storage && 'metrics' in storage
  const refSearch = useRef<Chart>(null)
  const refVCU = useRef<Chart>(null)
  const refStorage = useRef<Chart>(null)

  const pointerUpdate = (event: PointerEvent) => {
    refSearch.current?.dispatchExternalPointerEvent(event)
    refVCU.current?.dispatchExternalPointerEvent(event)
    refStorage.current?.dispatchExternalPointerEvent(event)
  }

  if (!hasSearch && !hasVCU && !hasStorage) {
    return null
  }

  const SearchChart = () => {
    if (!hasSearch) {
      return null
    }

    const searchRate = search.metrics.find(({ name }) => name === 'query_rate_per_sec')
    const searchLatency = search.metrics.find(({ name }) => name === 'query_latency_millis')
    const { min: rateMin, max: rateMax } = calculateDomain(searchRate)
    const { min: latencyMin, max: latencyMax } = calculateDomain(searchLatency)

    return (
      <EuiFlexItem>
        <EuiSplitPanel.Outer grow={true} hasBorder={true}>
          <EuiSplitPanel.Inner>
            <MetricTitle
              title='Search Rate / Search Latency'
              infoContent={
                <div>
                  <strong>Search rate</strong>
                  <p>
                    Number of search requests being executed per second on all shards hosted on the
                    node.
                  </p>
                  <EuiSpacer size='m' />
                  <strong>Search latency</strong>
                  <p>
                    Average latency for searching, which is the time it takes to execute searches
                    divided by the number of searches submitted to the node.
                  </p>
                </div>
              }
            />
            <EuiSpacer size='xs' />
            <Chart size={{ height: '200px' }} ref={refSearch}>
              <Settings
                baseTheme={baseTheme[theme.colorMode]}
                showLegend={false}
                pointerUpdateDebounce={0}
                onPointerUpdate={pointerUpdate}
              />

              <Tooltip
                headerFormatter={(pointerValue) =>
                  timeFormatter('DD-MM-YYYY hh:mm A')(pointerValue.value)
                }
              />

              <Axis
                id='bottom'
                position={Position.Bottom}
                showOverlappingTicks={true}
                labelFormat={timeFormatter('DD-MM hh:mm a')}
              />

              <Axis
                id='rate'
                groupId='rate'
                title='Search Rate'
                domain={{
                  min: rateMin,
                  max: rateMax,
                }}
                style={yAxisStyle}
                position={Position.Left}
                tickFormat={(d) => `${Number(d).toFixed(1)}/sec`}
              />

              <Axis
                id='latency'
                groupId='latency'
                title='Search Latency'
                domain={{
                  min: latencyMin,
                  max: latencyMax,
                }}
                style={yAxisStyle}
                position={Position.Right}
                tickFormat={(d) => `${Number(d).toFixed(0)} ms`}
              />

              <LineSeries
                key='Search Rate'
                id='Search Rate'
                groupId='rate'
                xScaleType={ScaleType.Time}
                yScaleType={ScaleType.Linear}
                xAccessor={0}
                yAccessors={[1]}
                data={searchRate?.data ?? []}
              />

              <LineSeries
                key='Search Latency'
                id='Search Latency'
                groupId='latency'
                xScaleType={ScaleType.Time}
                yScaleType={ScaleType.Linear}
                xAccessor={0}
                yAccessors={[1]}
                data={searchLatency?.data ?? []}
              />
            </Chart>
          </EuiSplitPanel.Inner>
        </EuiSplitPanel.Outer>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup gutterSize='l'>
      {hasSearch && <SearchChart />}
      {hasVCU && (
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='VCUs'
                infoContent='Amount of Virtual Compute Units (1 VCU contains 1GB of RAM and corresponding vCPU and local storage) allocated to each tier.'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refVCU}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  showLegend={true}
                  legendPosition={Position.Right}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  showOverlappingTicks={true}
                  labelFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis id='left' position={Position.Left} />

                {VCU.metrics.map((metricItem) => (
                  <LineSeries
                    key={metricItem.name}
                    id={metricItem.name}
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={metricItem.data}
                  />
                ))}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      )}
      {hasStorage && (
        <EuiFlexItem>
          <EuiSplitPanel.Outer grow={true} hasBorder={true}>
            <EuiSplitPanel.Inner>
              <MetricTitle
                title='Storage retained'
                infoContent='Amount of data that is being retained in long-term object storage (subject to data retention settings).'
              />
              <EuiSpacer size='xs' />
              <Chart size={{ height: '200px' }} ref={refStorage}>
                <Settings
                  baseTheme={baseTheme[theme.colorMode]}
                  legendPosition={Position.Right}
                  pointerUpdateDebounce={0}
                  onPointerUpdate={pointerUpdate}
                />

                <Axis
                  id='bottom'
                  position={Position.Bottom}
                  tickFormat={timeFormatter('DD-MM hh:mm a')}
                />

                <Axis id='left' position={Position.Left} tickFormat={(d) => `${d} GB`} />

                {storage.metrics.map((metricItem) => (
                  <AreaSeries
                    key={metricItem.name}
                    id={metricItem.name}
                    xScaleType={ScaleType.Time}
                    yScaleType={ScaleType.Linear}
                    xAccessor={0}
                    yAccessors={[1]}
                    data={metricItem.data}
                  />
                ))}
              </Chart>
            </EuiSplitPanel.Inner>
          </EuiSplitPanel.Outer>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}

export default withEuiTheme(ElasticsearchCharts)
