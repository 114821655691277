/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'

import {
  EuiButtonIcon,
  EuiCallOut,
  EuiConfirmModal,
  EuiCopy,
  EuiFieldText,
  EuiFlexGroup,
  EuiFormRow,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiTextColor,
  EuiToolTip,
  useGeneratedHtmlId,
} from '@elastic/eui'

import type { FC } from 'react'

const messages = defineMessages({
  title: {
    id: `dataRetentionConfirmationModal.title`,
    defaultMessage: `Delete older data?`,
  },
  calloutTitle: {
    id: `dataRetentionConfirmationModal.calloutTitle`,
    defaultMessage: `These changes cannot be reverted`,
  },
  calloutText: {
    id: `dataRetentionConfirmationModal.calloutText`,
    defaultMessage: `Data stream data older than the new maximum retention period will be deleted permanently from this project.`,
  },
  prompt: {
    id: `dataRetentionConfirmationModal.prompt`,
    defaultMessage: `Enter {projectNameWithCopyButton} to continue.`,
  },
  accessiblePrompt: {
    id: `dataRetentionConfirmationModal.accessiblePrompt`,
    defaultMessage: `Please type {projectName} to enable the confirm button.`,
  },
  confirmButton: {
    id: `dataRetentionConfirmationModal.confirmButton`,
    defaultMessage: `I understand, proceed`,
  },
  cancelButton: {
    id: `dataRetentionConfirmationModal.cancelButton`,
    defaultMessage: `Cancel`,
  },
})

type DataRetentionConfirmationModalProps = {
  projectName: string
  onCancel: () => void
  onConfirm: () => void
}

const DataRetentionConfirmationModal: FC<DataRetentionConfirmationModalProps> = ({
  projectName,
  onCancel,
  onConfirm,
}) => {
  const modalTitleId = useGeneratedHtmlId()

  const { formatMessage } = useIntl()

  const [typedProjectName, setTypedProjectName] = useState<string>('')

  const copyToClipboardMessage = formatMessage({
    id: 'project.copyToClipboard.body',
    defaultMessage: 'Copy project name to clipboard',
  })

  return (
    <EuiConfirmModal
      aria-labelledby={modalTitleId}
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonText={formatMessage(messages.confirmButton)}
      cancelButtonText={formatMessage(messages.cancelButton)}
      buttonColor='danger'
      confirmButtonDisabled={typedProjectName !== projectName}
    >
      <EuiModalHeader id={modalTitleId}>
        <EuiModalHeaderTitle>{formatMessage(messages.title)}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup direction='column' gutterSize='l'>
          <EuiCallOut
            title={formatMessage(messages.calloutTitle)}
            color='warning'
            iconType='warning'
          >
            <p>{formatMessage(messages.calloutText)}</p>
          </EuiCallOut>
          <EuiFormRow
            label={formatMessage(messages.prompt, {
              projectNameWithCopyButton: (
                <EuiTextColor color='accent'>
                  {projectName}{' '}
                  <EuiCopy textToCopy={projectName}>
                    {(copy) => (
                      <EuiToolTip content={copyToClipboardMessage}>
                        <EuiButtonIcon
                          aria-label={copyToClipboardMessage}
                          iconType='copy'
                          color='accent'
                          size='xs'
                          onClick={copy}
                        />
                      </EuiToolTip>
                    )}
                  </EuiCopy>
                </EuiTextColor>
              ),
            })}
          >
            <EuiFieldText
              name='confirmDataRetentionChange'
              value={typedProjectName}
              onChange={(e) => setTypedProjectName(e.target.value)}
              aria-label={formatMessage(messages.accessiblePrompt, {
                projectName,
              })}
            />
          </EuiFormRow>
        </EuiFlexGroup>
      </EuiModalBody>
      <EuiModalBody />
    </EuiConfirmModal>
  )
}

export default DataRetentionConfirmationModal
