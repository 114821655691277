/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import {
  getX509CertificateInfo,
  isCertificatePemFormat,
  isCertificateValid,
} from '@modules/security-idp-lib'

import validateDomain from '@/lib/validateDomain'

import { getCertExpiryInfoMessage } from '../../UserAuthenticationSummary/messages'

import { editFormMessages } from './messages'

import type { IdpUserInput } from '../types'
import type { FormikErrors } from 'formik'
import type { IntlShape } from 'react-intl'

/**
 * Validation for loginIdentifierPrefix
 * The login identifier prefix must be 1 to 25 characters long, begin and end with alphanumeric characters, and contain only lowercase alphanumeric characters and hyphens.
 * @param loginIdentifierPrefix
 * @returns boolean
 */
export const isLoginIdentifierPrefixValid = (loginIdentifierPrefix: string): boolean =>
  /^[a-z0-9](?:[-a-z0-9]{0,23}[a-z0-9])?$/.test(loginIdentifierPrefix)

export const validateUserInput =
  (
    formatMessage: IntlShape['formatMessage'],
  ): ((values: IdpUserInput) => FormikErrors<IdpUserInput>) =>
  (values: IdpUserInput) => {
    const errors: Partial<IdpUserInput> = {}

    // All fields are required
    Object.keys(values).forEach((key) => {
      if (!values[key]) {
        errors[key] = formatMessage(editFormMessages.requiredField)
      }
    })

    const validatingUrlFields = ['ssoUrl', 'issuer']
    validatingUrlFields.forEach((key) => {
      if (values[key] && !validateDomain(values[key])) {
        errors[key] = formatMessage(editFormMessages.invalidUrl)
      }
    })

    const publicCertificate = values.publicCertificate

    if (publicCertificate && !isCertificateValid(publicCertificate)) {
      errors.publicCertificate = formatMessage(getCertErrorMessage(publicCertificate))
    }

    if (
      values.loginIdentifierPrefix &&
      !isLoginIdentifierPrefixValid(values.loginIdentifierPrefix)
    ) {
      errors.loginIdentifierPrefix = formatMessage(editFormMessages.invalidLoginIdentifierPrefix)
    }

    return errors
  }

export const getCertErrorMessage = (cert: string) => {
  if (!isCertificatePemFormat(cert)) {
    return editFormMessages.notInPemFormat
  }

  const x509Cert = getX509CertificateInfo(cert)
  return getCertExpiryInfoMessage(x509Cert)
}
