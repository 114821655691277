/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'

import ModalPage from '@modules/cui/ModalPage'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { CuiAlert } from '@modules/cui/Alert'
import { hasOnlyWeakMfaDevice, hasStrongMfaDevice } from '@modules/mfa-lib/devices'
import { useRecommendedMfaDeviceTypes } from '@modules/mfa-management/hooks'

import DocLink from '@/components/DocLink' // eslint-disable-line import/no-restricted-paths

import DeviceOption from './DeviceOption'
import SetupAuthenticatorDevice from './SetupAuthenticatorDevice'
import SetupEmailDevice from './SetupEmailDevice'

import type { DeviceType } from '../../mfa-management/types'
import type { ReactNode } from 'react'

const EnableMfaPage: React.FunctionComponent<{
  devices: SaasAuthMfaDeviceResponse[] | undefined
  onContinue: () => void
}> = ({ devices, onContinue }) => {
  const [error, setError] = useState<ReactNode | null>(null)
  const [activeModal, setActiveModal] = useState<DeviceType | null>(null)

  const deviceTypes = useRecommendedMfaDeviceTypes()

  const title = (
    <EuiTitle size='m'>
      <h1 style={{ textAlign: 'center' }}>
        {hasOnlyWeakMfaDevice(devices) ? (
          <FormattedMessage
            id='mfaEnforcement.setup.title.hasOnlyWeak'
            defaultMessage='Update your multifactor authentication settings'
          />
        ) : (
          <FormattedMessage
            id='mfaEnforcement.setup.title'
            defaultMessage='Enable multifactor authentication'
          />
        )}
      </h1>
    </EuiTitle>
  )

  const instructions = (
    <EuiText size='s'>
      {hasOnlyWeakMfaDevice(devices) ? (
        <FormattedMessage
          id='mfaEnforcement.setup.instructions.hasOnlyWeak'
          defaultMessage='<p>To keep up with the latest security standards, multifactor authentication is now mandatory and we have removed SMS from the available MFA methods.</p><p>Set up at least one of the following MFA methods instead to access your account. Learn more</p>'
          values={{ p: (content) => <p>{content}</p> }}
        />
      ) : (
        <FormattedMessage
          id='mfaEnforcement.setup.instructions'
          defaultMessage="<p>To reinforce the security of your account, we're making multifactor authentication (MFA) mandatory. Enable at least one of the following MFA methods. <link>Learn more</link></p>"
          values={{
            p: (content) => <p>{content}</p>,
            link: (content) => <DocLink link='mfaDocLink'>{content}</DocLink>,
          }}
        />
      )}
    </EuiText>
  )

  const continueButton = (
    <EuiButton fill={true}>
      <FormattedMessage id='mfaEnforcement.continueButton' defaultMessage='Continue' />
    </EuiButton>
  )

  const footer = (
    <EuiText size='s' color='subdued'>
      <FormattedMessage
        id='mfaEnforcement.footer'
        defaultMessage='Need assistance? <link>Contact our Support team</link>'
        values={{
          link: (content) => <a href='mailto:support@elastic.co'>{content}</a>,
        }}
      />
    </EuiText>
  )

  const onSubmit = () => {
    if (hasStrongMfaDevice(devices)) {
      onContinue()
    } else {
      setError(
        <FormattedMessage
          id='mfaEnforcement.continueError'
          defaultMessage='You must set up at least one MFA method.'
        />,
      )
    }
  }

  return (
    <ModalPage footer={footer}>
      <EuiFlexGroup direction='column' alignItems='stretch' style={{ maxWidth: '40rem' }}>
        <EuiFlexItem>{title}</EuiFlexItem>
        <EuiFlexItem>{instructions}</EuiFlexItem>

        {error && <CuiAlert type='danger'>{error}</CuiAlert>}

        <EuiFlexGroup direction='column' alignItems='stretch'>
          {deviceTypes.map((type: DeviceType) => (
            <EuiFlexItem key={type}>
              <DeviceOption
                type={type}
                device={devices?.find((device) => device.device_type === type)}
                onSelect={setActiveModal}
              />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>

        <EuiFlexItem>
          <EuiSpacer size='m' />
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem grow={false} onClick={onSubmit}>
              {continueButton}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {activeModal === 'GOOGLE' && (
        <SetupAuthenticatorDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}

      {activeModal === 'EMAIL' && (
        <SetupEmailDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}
    </ModalPage>
  )
}

export default EnableMfaPage
