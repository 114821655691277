/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiBasicTable, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

import { resourceRoleLabelByRoleId } from '@modules/role-assignments-lib/types'
import type { ResourceRoleId, ResourceType } from '@modules/role-assignments-lib/types'
import {
  deploymentRoleLabels,
  elasticsearchRoleLabels,
  ResourceTypeDisplay,
  observabilityRoleLabels,
  securityRoleLabels,
} from '@modules/role-assignments-lib/messages'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'

import type { MessageDescriptor } from 'react-intl'
import type { FunctionComponent } from 'react'

export type AllProjectRoleAssignmentsTableItem<T extends ResourceType> = {
  resourceType: T
  roleIds: Array<ResourceRoleId<T>>
}

const AllProjectRoleAssignmentsTable: FunctionComponent<{
  items: Array<AllProjectRoleAssignmentsTableItem<ResourceType>>
}> = ({ items }) => {
  const [isFlagReady, flags] = useFlagsWhenLoaded()
  const customRolesEnabled = isFlagReady && flags.serverlessCustomRoles
  return (
    <EuiBasicTable
      data-test-id='all-role-assignments-table'
      items={items}
      columns={getColumns(customRolesEnabled)}
    />
  )
}

const getColumns = (
  customRolesEnabled: boolean,
): Array<EuiBasicTableColumn<AllProjectRoleAssignmentsTableItem<ResourceType>>> => [
  {
    name: <FormattedMessage id='roles-assignments.type' defaultMessage='Type' />,
    field: 'resourceType',
    render: (resourceType: ResourceType) => (
      <ResourceTypeDisplay resourceType={resourceType} plural={true} capitalize={true} />
    ),
    width: '55%',
  },
  {
    name: (
      <FormattedMessage
        id={'roles-assignments.allAssignmentRoleColumn'}
        defaultMessage={'{count, plural, one {Role} other {Roles}}'}
        values={{ count: customRolesEnabled ? 2 : 1 }}
      />
    ),
    field: 'roleIds',
    render: (roleIds: string[], tableItem) => {
      const rolesToProcess = customRolesEnabled ? roleIds : roleIds.slice(0, 1)
      const roleItems = rolesToProcess.map((roleId) => {
        const roleLabel = resourceRoleLabelByRoleId[roleId]
        let predefinedLabels: Record<string, MessageDescriptor> = deploymentRoleLabels

        if (tableItem.resourceType === 'elasticsearch') {
          predefinedLabels = elasticsearchRoleLabels
        } else if (tableItem.resourceType === 'observability') {
          predefinedLabels = observabilityRoleLabels
        } else if (tableItem.resourceType === 'security') {
          predefinedLabels = securityRoleLabels
        }

        return (
          <EuiFlexItem grow={false} key={tableItem.resourceType.concat(roleId)}>
            <FormattedMessage {...predefinedLabels[roleLabel]} />
          </EuiFlexItem>
        )
      })
      return (
        <EuiFlexGroup direction='column' justifyContent='flexStart' gutterSize='xs'>
          {roleItems}
        </EuiFlexGroup>
      )
    },
    width: '45%',
  },
]

export default AllProjectRoleAssignmentsTable
