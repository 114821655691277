/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiBadge, EuiBasicTable, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import type {
  ResourceType,
  RoleAssignmentsTableItem,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import { resourceRoleLabelByRoleId } from '@modules/role-assignments-lib/types'
import {
  deploymentRoleLabels,
  elasticsearchRoleLabels,
  ResourceTypeDisplay,
  observabilityRoleLabels,
  securityRoleLabels,
} from '@modules/role-assignments-lib/messages'
import { getProjectSpecificViewerRole } from '@modules/role-assignments-lib'
import type { ProjectType } from '@modules/ui-types/projects'
import {
  useGetElasticsearchProjectRolesQuery,
  useGetSecurityProjectRolesQuery,
} from '@modules/project-user-lib/hooks/get'
import type { components } from '@modules/project-user-api/v1/types'

import type { MessageDescriptor } from 'react-intl'
import type { FunctionComponent } from 'react'

const CustomRoleBadge: FunctionComponent<{
  item: RoleAssignmentsTableItem<ResourceType>
  roleDescriptor: TableItemRoleDescriptor
  customRolesEnabled: boolean
}> = ({ item, roleDescriptor, customRolesEnabled }) => {
  let customRoles: string[] = []

  const singleSelection = item.resourceType === 'deployment' || !customRolesEnabled

  const elasticsearchQuery = useGetElasticsearchProjectRolesQuery(item.id, {
    enabled: !singleSelection && item.resourceType === 'elasticsearch',
  })

  const securityQuery = useGetSecurityProjectRolesQuery(item.id, {
    enabled: !singleSelection && item.resourceType === 'security',
  })

  let data: components['schemas']['ProjectRolesByProjectID'] | undefined
  let isLoading = false

  if (item.resourceType === 'elasticsearch') {
    data = elasticsearchQuery.data
    isLoading = elasticsearchQuery.isLoading
  } else if (item.resourceType === 'security') {
    data = securityQuery.data
    isLoading = securityQuery.isLoading
  }

  if (isLoading) {
    return null
  }

  customRoles = !data
    ? []
    : (data[item.id]?.roles ?? []).map((role: { name: string; description: string }) => role.name)

  return (
    <EuiFlexGroup gutterSize='xs' responsive={false}>
      {customRoles.length > 0 && !customRoles.includes(roleDescriptor.roleId) && (
        <EuiFlexItem grow={false}>
          <EuiBadge color='danger'>
            <FormattedMessage id='roles-assignments.deleted-badge' defaultMessage='Deleted' />
          </EuiBadge>
        </EuiFlexItem>
      )}
      {roleDescriptor.roleId}
    </EuiFlexGroup>
  )
}

const ProjectRoleAssignmentsTable: FunctionComponent<{
  items: Array<RoleAssignmentsTableItem<ResourceType>>
  customRolesEnabled: boolean
}> = ({ items, customRolesEnabled }) => (
  <EuiBasicTable
    data-test-id='instance-role-assignments-table'
    noItemsMessage={
      <FormattedMessage
        id='roles-assignments.no-instance-found'
        defaultMessage='No instance found'
      />
    }
    items={items}
    columns={getColumns(customRolesEnabled)}
  />
)

const getColumns = (
  customRolesEnabled: boolean,
): Array<EuiBasicTableColumn<RoleAssignmentsTableItem<ResourceType>>> => [
  {
    name: <FormattedMessage id='roles-assignments.name' defaultMessage='Name' />,
    field: 'name',
    sortable: ({ name }) => name.toLowerCase(),
    render: (name: string) => <EuiText size='s'>{name}</EuiText>,
    truncateText: true,
    width: '34%',
  },
  {
    name: <FormattedMessage id='roles-assignments.type' defaultMessage='Type' />,
    field: 'resourceType',
    render: (resourceType: ResourceType) => (
      <ResourceTypeDisplay resourceType={resourceType} capitalize={true} />
    ),
    width: '33%',
  },
  {
    name: (
      <FormattedMessage
        id={'roles-assignments.roleColumn'}
        defaultMessage={'{count, plural, one {Role} other {Roles}}'}
        values={{ count: customRolesEnabled ? 2 : 1 }}
      />
    ),
    field: 'roles',
    render: (
      roles: TableItemRoleDescriptor[],
      roleAssignmentItem: RoleAssignmentsTableItem<ResourceType>,
    ) => {
      const roleToProcess = customRolesEnabled ? roles : roles.slice(0, 1)
      const roleItems = roleToProcess.map((roleDescriptor) => {
        if (customRolesEnabled && roleDescriptor.isCustomRole) {
          return (
            <CustomRoleBadge
              key={roleAssignmentItem.id.concat(roleDescriptor.roleId)}
              item={roleAssignmentItem}
              roleDescriptor={roleDescriptor}
              customRolesEnabled={customRolesEnabled}
            />
          )
        }

        // If we encoutner a custom role when custom roles is disabled,
        // override it a with the appropriate viewer predfined role
        let predefinedRoleId = roleDescriptor.roleId

        if (roleDescriptor.isCustomRole) {
          predefinedRoleId = getProjectSpecificViewerRole(
            roleAssignmentItem.resourceType as ProjectType,
          )
        }

        const roleLabel = resourceRoleLabelByRoleId[predefinedRoleId]
        let predefinedLabels: Record<string, MessageDescriptor> = deploymentRoleLabels

        if (roleAssignmentItem.resourceType === 'elasticsearch') {
          predefinedLabels = elasticsearchRoleLabels
        } else if (roleAssignmentItem.resourceType === 'observability') {
          predefinedLabels = observabilityRoleLabels
        } else if (roleAssignmentItem.resourceType === 'security') {
          predefinedLabels = securityRoleLabels
        }

        return (
          <EuiFlexItem grow={false} key={roleAssignmentItem.id.concat(roleDescriptor.roleId)}>
            <FormattedMessage {...predefinedLabels[roleLabel]} />
          </EuiFlexItem>
        )
      })

      return (
        <EuiFlexGroup direction='column' justifyContent='flexStart' gutterSize='xs'>
          {roleItems}
        </EuiFlexGroup>
      )
    },
    width: '33%',
  },
]

export default ProjectRoleAssignmentsTable
