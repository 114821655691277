/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { isEmpty } from 'lodash'
import { stringify } from 'query-string'

import type { PlainHashMap, BillingSubscriptionLevel } from '@modules/ui-types'

import { getConfigForKey } from '@/store'

import { registerUrl } from '../../lib/urlBuilder'

export const awsBillingUrl = `https://console.aws.amazon.com/billing/home?#/bill`
export const azureBillingUrl = `https://portal.azure.com/#blade/Microsoft_Azure_CostManagement/Menu/costanalysis`
export const gcpBillingUrl = `https://console.cloud.google.com/billing`
export const gcpConsoleUrl = `https://console.cloud.google.com/marketplace/details/endpoints/elasticsearch-service.gcpmarketplace.elastic.co `

export const awsMarketplaceSignUpUrl = `https://ela.st/aws-marketplace-signup`
export const gcpMarketplaceSignUpUrl = `https://ela.st/gcp-marketplace-signup`
export const azureMarketplaceSignUpUrl = `https://ela.st/azure-marketplace`

export const contactUs = `https://www.elastic.co/cloud/contact`
export const externalPricing = `https://www.elastic.co/pricing`

export const privacyUrl = `https://www.elastic.co/legal/privacy-statement`
export const termOfService = `https://www.elastic.co/legal/elastic-cloud-account-terms`
export const payingTermsOfService = `https://www.elastic.co/agreements/global/cloud-services-monthly`

export const supportPortalUrl = `https://support.elastic.co`
export const googleReCaptchaEnterpriseUrl = `https://www.google.com/recaptcha/enterprise.js`
export function accountUrl(): string {
  return `/account`
}

export function accountCostAnalysisUrl(): string {
  return `/account/cost-analysis`
}

export function accountContactsUrl(): string {
  return `/account/contacts`
}

export function accountDetailsUrl(): string {
  return `/account/profile`
}

export function accountSecurityUrl(): string {
  return `/account/security`
}

export function userAuthenticationUrl(): string {
  return `/account/idp/user-authentication`
}

export function billingUrl(): string {
  return `/billing`
}

export function billingOverviewUrl(): string {
  return `/billing/overview`
}

export function billingHistoryUrl(): string {
  return `/billing/history`
}

export function billingUsageUrl(organizationId?: string): string {
  if (getConfigForKey(`APP_NAME`) === `adminconsole`) {
    return `/organizations/${organizationId}/usage`
  }

  return `/billing/usage`
}

export function billingSubscriptionUrl(): string {
  return `/billing/subscription`
}

export function accountMembersUrl(): string {
  return `/account/members`
}

export function apiKeysUrl(): string {
  return `/account/keys`
}

export function trafficFiltersUrl(): string {
  return `/deployment-features/traffic-filters`
}

export function trustManagementUrl(): string {
  return `/deployment-features/trust-management`
}

export function pricingUrl({ level }: { level?: BillingSubscriptionLevel } = {}): string {
  const baseUrl = `/pricing`

  if (!level) {
    return baseUrl
  }

  const queryString = stringify({
    level,
  })
  const pricingUrl = `${baseUrl}?${queryString}`
  return pricingUrl
}

export function pricingTableUrl(): string {
  return `/cloud-pricing-table`
}

export function signUp(queryStringParams: PlainHashMap = {}): string {
  const baseUrl = registerUrl()

  if (isEmpty(queryStringParams)) {
    return baseUrl
  }

  const queryString = stringify(queryStringParams)
  const signUpUrl = `${baseUrl}?${queryString}`

  return signUpUrl
}
