/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'

import type { EuiBasicTableColumn } from '@elastic/eui'
import { EuiBasicTable } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { components } from '@modules/billing-api/v1/types'

import { getBillingDimensionName } from './utils'

const columns: Array<EuiBasicTableColumn<components['schemas']['ProductLineItem']>> = [
  {
    name: <FormattedMessage id='product-details-panel.name' defaultMessage='Component' />,
    width: '43%',
    field: 'name',
  },
  {
    name: (
      <FormattedMessage
        id='product-details-panel.billing-dimension'
        defaultMessage='Billing dimension'
      />
    ),
    render: ({ type }) => getBillingDimensionName(type),
  },
  {
    name: <FormattedMessage id='product-details-panel.quantity' defaultMessage='Quantity' />,
    render: ({ quantity }) => quantity.formatted_value,
  },
  {
    name: (
      <FormattedMessage
        id='product-details-panel.item-price-name'
        defaultMessage='Current usage rates (in ECU)'
      />
    ),
    render: ({ rate }) => rate.formatted_value,
  },
  {
    name: <FormattedMessage id='product-details-panel.cost' defaultMessage='Cost (in ECU)' />,
    render: ({ total_ecu }) => (
      <CuiElasticConsumptionUnits value={total_ecu} unit='none' dp={4} withSymbol={false} />
    ),
    align: 'right',
  },
]

const tableCss = css({
  paddingLeft: 15,
  paddingRight: 30,
  '& tr:last-of-type td': { borderBottom: 'none ' },
})

interface Props {
  product_line_items: Array<components['schemas']['ProductLineItem']>
}

const ProductDetailsPanel = ({ product_line_items }: Props) => (
  <EuiBasicTable items={product_line_items} columns={columns} css={tableCss} />
)

export default ProductDetailsPanel
