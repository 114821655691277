/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import type { EuiButtonGroupOptionProps } from '@elastic/eui'
import { EuiButtonGroup, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'

import timeMessages from '@modules/messages/timeMessages'

type Basis = 'hourly' | 'monthly'

type Props = {
  hourlyRate: number
}

const Total: React.FunctionComponent<Props> = ({ hourlyRate }) => {
  const { formatMessage } = useIntl()

  const [basis, setBasis] = useState<Basis>('monthly')

  const options: EuiButtonGroupOptionProps[] = [
    {
      id: 'hourly',
      label: <FormattedMessage {...timeMessages.hourly} />,
    },
    {
      id: 'monthly',
      label: <FormattedMessage {...timeMessages.monthly} />,
    },
  ]

  const total = basis === 'hourly' ? hourlyRate.toFixed(4) : (hourlyRate * 24 * 30).toFixed(2)

  return (
    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
      <EuiFlexItem grow={false}>
        <EuiButtonGroup
          buttonSize='compressed'
          legend={formatMessage({ id: 'trialSummary.timeBasis', defaultMessage: 'Time basis' })}
          options={options}
          idSelected={basis}
          onChange={(id: Basis) => setBasis(id)}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText textAlign='right'>
          <strong>
            <s>${total}</s>
          </strong>
        </EuiText>
        <EuiText color='success' textAlign='right'>
          <strong>
            <FormattedMessage
              id='trialSummary.freeDuringTrial'
              defaultMessage='Free during trial'
            />
          </strong>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default Total
