/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'
import type { AnyProject } from '@modules/project-api/types'
import ProjectApiErrorCallout from '@modules/project-components/ProjectApiErrorCallout'
import { usePatchSecurityProjectMutation } from '@modules/project-user-lib/hooks/update'
import { useToggle } from '@modules/utils/hooks/useToggle'
import {
  retentionDaysToSecurityApiAdapter,
  securityApiToRetentionDaysAdapter,
} from '@modules/project-lib/dataRetention'

import DataRetentionMessage from '../../project-components/DataRetentionMessage'

import DataRetentionControlsForm from './DataRetentionControlsForm'
import DataRetentionDescription from './DataRetentionDescription'
import DataRetentionDisplay from './DataRetentionDisplay'

import type { VFC } from 'react'

export type Props = {
  project: AnyProject
}

const messages = defineMessages({
  updateSuccess: {
    id: 'project.dataRetention.updateSuccess',
    defaultMessage: 'Data retention settings updated',
  },
})

const DataRetention: VFC<Props> = ({ project }) => {
  const { formatMessage } = useIntl()

  const { mutate: updateSecurityProject, error } = usePatchSecurityProjectMutation()

  const [isEditing, toggleEditing] = useToggle(false)

  if (project.type !== 'security') {
    return <DataRetentionMessage project={project} />
  }

  const { default_retention_days, max_retention_days } = project.search_lake?.data_retention ?? {}

  const defaultRetentionDays = securityApiToRetentionDaysAdapter(default_retention_days)

  const maximumRetentionDays = securityApiToRetentionDaysAdapter(max_retention_days)

  return (
    <EuiPanel data-test-subj='editDataRetention' paddingSize='l' hasBorder={true}>
      <EuiFlexGroup gutterSize='xl' alignItems='stretch'>
        <EuiFlexItem grow={1}>
          <div style={{ marginRight: '2rem' }}>
            <DataRetentionDescription isEditing={isEditing} project={project} />
          </div>
        </EuiFlexItem>
        <EuiFlexItem grow={1}>
          <EuiFlexGroup direction='column' justifyContent='spaceBetween' gutterSize='l'>
            <DataRetentionDisplay
              project={project}
              isEditing={isEditing}
              toggleEditing={toggleEditing}
            />

            {isEditing && (
              <DataRetentionControlsForm
                currentMaximumRetentionDays={maximumRetentionDays}
                currentDefaultRetentionDays={defaultRetentionDays}
                onSubmit={handleDataRetentionUpdate}
                projectName={project.name}
                error={error && <ProjectApiErrorCallout error={error} />}
              />
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )

  function handleDataRetentionUpdate(
    updatedMaximumRetentionDays: number,
    updatedDefaultRetentionDays: number,
  ): void {
    updateSecurityProject(
      {
        id: project.id,
        body: {
          search_lake: {
            data_retention: {
              max_retention_days: retentionDaysToSecurityApiAdapter(updatedMaximumRetentionDays),
              default_retention_days: retentionDaysToSecurityApiAdapter(
                updatedDefaultRetentionDays,
              ),
            },
          },
        },
      },
      {
        onSuccess: () => {
          addToast({
            color: 'success',
            iconType: 'check',
            title: formatMessage(messages.updateSuccess),
          })

          toggleEditing()
        },
      },
    )
  }
}

export default DataRetention
