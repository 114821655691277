/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { requests } from './apmHttpRequestsPatterns'

export const httpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'] as const

export type HttpMethod = (typeof httpMethods)[number]

export const getHttpMethodFromString = (input: string): HttpMethod | undefined => {
  for (const method of httpMethods) {
    if (input.startsWith(method)) {
      return method
    }
  }

  return
}

export const getMatchingStaticRequest = (url: string) => {
  const urlWithoutQueryParameters = url.split('?')[0]
  const method = getHttpMethodFromString(url)
  const staticRequest =
    method && requests.static[method].find((request) => request === urlWithoutQueryParameters)
  return staticRequest
}

export function getMatchingDynamicRequest(url: string) {
  let dynamicRequest: string | undefined
  const method = getHttpMethodFromString(url)

  if (!method) {
    return
  }

  for (const request of requests.dynamic[method]) {
    const regex = request.pattern

    if (url.match(regex)) {
      dynamicRequest = request.request
      break
    }
  }

  return dynamicRequest
}

export function getGenericRequestName(url: string) {
  const staticRequest = getMatchingStaticRequest(url)

  if (staticRequest) {
    return staticRequest
  }

  const dynamicRequest = getMatchingDynamicRequest(url)

  if (dynamicRequest) {
    return dynamicRequest
  }

  return url
}
