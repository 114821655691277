/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import {
  EuiButton,
  EuiCallOut,
  EuiEmptyPrompt,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui'

import { useOrganizationIdp } from '@modules/security-idp-lib/hooks'
import { getRemainingDaysToExpiry, getX509CertificateInfo } from '@modules/security-idp-lib'

import { userAuthenticationUrl } from '@/apps/userconsole/urls'

import UserAuthenticationSummary from '../UserAuthenticationSummary'

import { getLoginMethodMessage } from './messages'

import type { Props } from './types'

const UserAuthentication = ({
  organizationId,
  enforceAuthenticationMethod,
  permissions: { isLoading: isLoadingPermission, hasUpdateOrganizationIdp, hasViewOrganizationIdp },
}: Props) => {
  const { isLoading: isLoadingIdp, data: idpInfo } = useOrganizationIdp(organizationId)

  const history = useHistory()
  const certificateInfo = getX509CertificateInfo(
    idpInfo?.configuration.saml_idp.public_certificate[0],
  )
  const remainingDaysToCertExpiry = certificateInfo
    ? getRemainingDaysToExpiry(certificateInfo)
    : undefined

  const hasExpiryCallout = idpInfo && (!remainingDaysToCertExpiry || remainingDaysToCertExpiry < 0)

  const isLoading = isLoadingPermission || isLoadingIdp

  if (!isLoading && !hasViewOrganizationIdp) {
    return null
  }

  return (
    <EuiPanel paddingSize='xl'>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTitle size='m'>
            <h1>User Authentication</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {hasUpdateOrganizationIdp && (
            <EuiButton
              size='m'
              isLoading={isLoading}
              onClick={() => history.push(userAuthenticationUrl())}
            >
              <FormattedMessage
                id='user-authentication.configure-sso-button'
                defaultMessage='Configure SSO'
              />
            </EuiButton>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {hasExpiryCallout && <ErrorCallout />}
          <UserAuthenticationInfo
            isEnabledSamlSso={idpInfo?.configuration.enabled}
            isEnforcedSamlSso={enforceAuthenticationMethod === 'sso'}
            idpInfo={idpInfo}
          />
        </Fragment>
      )}
    </EuiPanel>
  )
}

const UserAuthenticationInfo = ({ isEnabledSamlSso, isEnforcedSamlSso, idpInfo }) => {
  const { formatMessage } = useIntl()
  const loginMethodMessage = formatMessage(
    getLoginMethodMessage(isEnabledSamlSso, isEnforcedSamlSso),
  )

  return (
    <Fragment>
      <EuiFlexGroup className='no-data-flex-group'>
        <EuiFlexItem className='no-data-left-panel'>
          <EuiTitle size='xs'>
            <h3>
              <FormattedMessage id='user-authentication.login-identifier' defaultMessage='Login' />
            </h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem className='no-data-right-panel'>
          <EuiText>{loginMethodMessage}</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size='l' />
      {isEnabledSamlSso && <UserAuthenticationSummary idpInfo={idpInfo} />}
    </Fragment>
  )
}

const Loading = () => (
  <EuiEmptyPrompt
    icon={<EuiLoadingSpinner size='l' />}
    title={
      <FormattedMessage
        id='organization.security.user-authentication.loading'
        defaultMessage='Loading user authentication information'
      />
    }
    color='subdued'
  />
)

const ErrorCallout = () => (
  <Fragment>
    <EuiCallOut
      title={
        <FormattedMessage
          id='organization.security.user-authentication.error'
          defaultMessage='Users in your organization cannot login via SAML SSO'
        />
      }
      color='danger'
      iconType='warning'
    >
      <EuiText>
        <FormattedMessage
          id='organization.security.user-authentication.error-x509-expired'
          defaultMessage='Your public x509 certificate has expired, preventing users from logging in via SAML SSO. Provide a valid certificate to restore SAML SSO access.'
        />
      </EuiText>
      <EuiSpacer size='s' />
      <EuiButton fill={true} color='danger'>
        <FormattedMessage
          id='organization.security.user-authentication.error-x509-expired-action'
          defaultMessage='Update certificate'
        />
      </EuiButton>
    </EuiCallOut>
    <EuiSpacer />
  </Fragment>
)

export default UserAuthentication
