/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import type { EuiComboBoxOptionOption } from '@elastic/eui'
import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiSkeletonText,
} from '@elastic/eui'

import { updateTableItemRoles } from '@modules/role-assignments-lib'
import {
  deploymentRoleDescriptions,
  deploymentRoleLabels,
  elasticsearchRoleDescriptions,
  elasticsearchRoleLabels,
  observabilityRoleDescriptions,
  observabilityRoleLabels,
  securityRoleDescriptions,
  securityRoleLabels,
} from '@modules/role-assignments-lib/messages'
import type {
  ResourceRoleAssignment,
  ResourceType,
  RoleAssignmentsTableItem,
  RoleOptionDefinition,
  TableItemRoleDescriptor,
} from '@modules/role-assignments-lib/types'
import {
  deploymentRoleIdsByLabel,
  elasticsearchRoleIdsByLabel,
  observabilityRoleIdsByLabel,
  securityRoleIdsByLabel,
} from '@modules/role-assignments-lib/types'
import type { components } from '@modules/project-user-api/v1/types'
import {
  useGetElasticsearchProjectRolesQuery,
  useGetSecurityProjectRolesQuery,
} from '@modules/project-user-lib/hooks/get'

const deploymentOptions: RoleOptionDefinition[] = [
  {
    roleId: deploymentRoleIdsByLabel.admin,
    labelDescriptor: deploymentRoleLabels.admin,
    descriptionDescriptor: deploymentRoleDescriptions.admin,
  },
  {
    roleId: deploymentRoleIdsByLabel.editor,
    labelDescriptor: deploymentRoleLabels.editor,
    descriptionDescriptor: deploymentRoleDescriptions.editor,
  },
  {
    roleId: deploymentRoleIdsByLabel.viewer,
    labelDescriptor: deploymentRoleLabels.viewer,
    descriptionDescriptor: deploymentRoleDescriptions.viewer,
  },
]

const elasticsearchOptions: RoleOptionDefinition[] = [
  {
    roleId: elasticsearchRoleIdsByLabel.admin,
    labelDescriptor: elasticsearchRoleLabels.admin,
    descriptionDescriptor: elasticsearchRoleDescriptions.admin,
  },
  {
    roleId: elasticsearchRoleIdsByLabel.developer,
    labelDescriptor: elasticsearchRoleLabels.developer,
    descriptionDescriptor: elasticsearchRoleDescriptions.developer,
  },
  {
    roleId: elasticsearchRoleIdsByLabel.viewer,
    labelDescriptor: elasticsearchRoleLabels.viewer,
    descriptionDescriptor: elasticsearchRoleDescriptions.viewer,
  },
]

const observabilityOptions: RoleOptionDefinition[] = [
  {
    roleId: observabilityRoleIdsByLabel.admin,
    labelDescriptor: observabilityRoleLabels.admin,
    descriptionDescriptor: observabilityRoleDescriptions.admin,
  },
  {
    roleId: observabilityRoleIdsByLabel.editor,
    labelDescriptor: observabilityRoleLabels.editor,
    descriptionDescriptor: observabilityRoleDescriptions.editor,
  },
  {
    roleId: observabilityRoleIdsByLabel.viewer,
    labelDescriptor: observabilityRoleLabels.viewer,
    descriptionDescriptor: observabilityRoleDescriptions.viewer,
  },
]

const securityOptions: RoleOptionDefinition[] = [
  {
    roleId: securityRoleIdsByLabel.admin,
    labelDescriptor: securityRoleLabels.admin,
    descriptionDescriptor: securityRoleDescriptions.admin,
  },
  {
    roleId: securityRoleIdsByLabel.editor,
    labelDescriptor: securityRoleLabels.editor,
    descriptionDescriptor: securityRoleDescriptions.editor,
  },
  {
    roleId: securityRoleIdsByLabel.viewer,
    labelDescriptor: securityRoleLabels.viewer,
    descriptionDescriptor: securityRoleDescriptions.viewer,
  },
  {
    roleId: securityRoleIdsByLabel.tier1Analyst,
    labelDescriptor: securityRoleLabels.tier1Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier1Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.tier2Analyst,
    labelDescriptor: securityRoleLabels.tier2Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier2Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.tier3Analyst,
    labelDescriptor: securityRoleLabels.tier3Analyst,
    descriptionDescriptor: securityRoleDescriptions.tier3Analyst,
  },
  {
    roleId: securityRoleIdsByLabel.threatIntelligenceAnalyst,
    labelDescriptor: securityRoleLabels.threatIntelligenceAnalyst,
    descriptionDescriptor: securityRoleDescriptions.threatIntelligenceAnalyst,
  },
  {
    roleId: securityRoleIdsByLabel.ruleAuthor,
    labelDescriptor: securityRoleLabels.ruleAuthor,
    descriptionDescriptor: securityRoleDescriptions.ruleAuthor,
  },
  {
    roleId: securityRoleIdsByLabel.socManager,
    labelDescriptor: securityRoleLabels.socManager,
    descriptionDescriptor: securityRoleDescriptions.socManager,
  },
  {
    roleId: securityRoleIdsByLabel.endpointOperationsAnalyst,
    labelDescriptor: securityRoleLabels.endpointOperationsAnalyst,
    descriptionDescriptor: securityRoleDescriptions.endpointOperationsAnalyst,
  },
  {
    roleId: securityRoleIdsByLabel.platformEngineer,
    labelDescriptor: securityRoleLabels.platformEngineer,
    descriptionDescriptor: securityRoleDescriptions.platformEngineer,
  },
  {
    roleId: securityRoleIdsByLabel.detectionsAdmin,
    labelDescriptor: securityRoleLabels.detectionsAdmin,
    descriptionDescriptor: securityRoleDescriptions.detectionsAdmin,
  },
  {
    roleId: securityRoleIdsByLabel.endpointPolicyManager,
    labelDescriptor: securityRoleLabels.endpointPolicyManager,
    descriptionDescriptor: securityRoleDescriptions.endpointPolicyManager,
  },
]

const useGetOptions = () => {
  const { formatMessage } = useIntl()

  const predefinedGroup = {
    label: formatMessage({
      id: 'roles-assignments.predefined-roles-group.label',
      defaultMessage: 'Predefined roles',
    }),
    isGroupLabelOption: true,
    'data-test-subj': 'predefinedRolesGroup',
  }

  const noCustomRolesPlaceholder = {
    value: '',
    label: formatMessage({
      id: 'roles-assignments.no-custom-roles-placeholder',
      defaultMessage: 'No custom roles',
    }),
    disabled: true,
  } as EuiComboBoxOptionOption<string>

  const getPredefinedOptions = <T extends ResourceType>(
    resourceType: T,
  ): Array<EuiComboBoxOptionOption<string>> => {
    let predefinedRoles: RoleOptionDefinition[]

    switch (resourceType) {
      case 'deployment':
        predefinedRoles = deploymentOptions
        break
      case 'elasticsearch':
        predefinedRoles = elasticsearchOptions
        break
      case 'observability':
        predefinedRoles = observabilityOptions
        break
      case 'security':
        predefinedRoles = securityOptions
        break
      default:
        predefinedRoles = []
        break
    }

    return predefinedRoles.map(
      (roleDef) =>
        ({
          value: roleDef.roleId,
          label: formatMessage({ ...roleDef.labelDescriptor }),
          toolTipContent: formatMessage({ ...roleDef.descriptionDescriptor }),
        } as EuiComboBoxOptionOption<string>),
    )
  }

  const getOptions = <T extends ResourceType>(
    resourceType: T,
    displayCustomRoles: boolean,
    customRoleOptions: Array<EuiComboBoxOptionOption<string>>,
    roleManagementUrl?: string,
  ): Array<EuiComboBoxOptionOption<string>> => {
    const predefinedOptions = getPredefinedOptions(resourceType)

    if (resourceType === 'deployment') {
      return predefinedOptions
    }

    const customRolesGroup = {
      label: formatMessage({
        id: 'roles-assignments.custom-roles-group.label',
        defaultMessage: 'Custom roles',
      }),
      isGroupLabelOption: true,
      ...(roleManagementUrl !== undefined && {
        append: <AddRoleLinkButton roleManagementUrl={roleManagementUrl} />,
      }),
      'data-test-subj': 'customRolesGroup',
    }

    const customOptionsToDisplay =
      customRoleOptions.length > 0 ? customRoleOptions : [noCustomRolesPlaceholder]

    // To Do: remove observability conditions below when custom roles are enabled for oblt projects
    return [
      ...(displayCustomRoles ? [predefinedGroup] : []),
      ...predefinedOptions,
      ...(displayCustomRoles ? [customRolesGroup] : []),
      ...(displayCustomRoles ? customOptionsToDisplay : []),
    ]
  }

  const noRolesPlaceholder = (singleSelection: boolean) =>
    formatMessage(
      {
        id: 'roles-assignments.none.placeholder',
        defaultMessage: 'No {count, plural, one {role} other {roles}} assigned',
      },
      {
        count: singleSelection ? 1 : 2,
      },
    )

  return { getOptions, getPredefinedOptions, noRolesPlaceholder }
}

const getSelectedOptions = (
  roles: TableItemRoleDescriptor[],
  options: Array<EuiComboBoxOptionOption<string>>,
  multipleSelectionEnabled: boolean,
): Array<EuiComboBoxOptionOption<string>> => {
  const selectedOptions: Array<EuiComboBoxOptionOption<string>> = []

  if (roles.length) {
    const rolesToProcess = multipleSelectionEnabled ? roles : roles.slice(0, 1)

    rolesToProcess.forEach((role) => {
      const found = options.find((option) => option.value === role.roleId)

      if (found) {
        selectedOptions.push(found)
      }
    })
  }

  return selectedOptions
}

const AddRoleLinkButton = ({ roleManagementUrl }: { roleManagementUrl: string }) => (
  <EuiButtonEmpty css={{ marginLeft: 'auto' }} flush='right'>
    <EuiIcon type='plusInCircle' size='s' color='primary' style={{ marginRight: '8px' }} />
    <EuiLink href={roleManagementUrl}>
      <FormattedMessage
        id='roles-assignments.add-custom-role.message'
        defaultMessage='Add custom role'
      />
    </EuiLink>
  </EuiButtonEmpty>
)

export interface RoleSelectorProps<T extends ResourceType, R extends ResourceRoleAssignment<T>> {
  resourceType: T
  items: Array<RoleAssignmentsTableItem<T>>
  tableItemsToRoleAssignment: (items: Array<RoleAssignmentsTableItem<T>>) => R[]
  onChangeRoleAssignments: (roleAssignments: Array<ResourceRoleAssignment<T>>) => void
  roles: TableItemRoleDescriptor[]
  id: string
  roleManagementUrl?: string
  isDisabled: boolean
  customRolesEnabled: boolean
}

export const RoleSelector = <T extends ResourceType, R extends ResourceRoleAssignment<T>>(
  props: RoleSelectorProps<T, R>,
) => {
  const {
    resourceType,
    items,
    tableItemsToRoleAssignment,
    onChangeRoleAssignments,
    roles,
    id: projectId,
    roleManagementUrl,
    isDisabled,
    customRolesEnabled,
  } = props

  const { getOptions, getPredefinedOptions, noRolesPlaceholder } = useGetOptions()
  const predefinedRoles = getPredefinedOptions(resourceType)

  const singleSelection = resourceType === 'deployment' || !customRolesEnabled
  const displayCustomRoles =
    customRolesEnabled && (resourceType === 'elasticsearch' || resourceType === 'security')

  let customRoleOptions: Array<EuiComboBoxOptionOption<string>> = []

  const elasticsearchQuery = useGetElasticsearchProjectRolesQuery(projectId, {
    enabled: !singleSelection && resourceType === 'elasticsearch',
  })

  const securityQuery = useGetSecurityProjectRolesQuery(projectId, {
    enabled: !singleSelection && resourceType === 'security',
  })

  let data: components['schemas']['ProjectRolesByProjectID'] | undefined = {}
  let isLoading = false

  if (resourceType === 'elasticsearch') {
    data = elasticsearchQuery.data
    isLoading = elasticsearchQuery.isLoading
  } else if (resourceType === 'security') {
    data = securityQuery.data
    isLoading = securityQuery.isLoading
  }

  if (isLoading) {
    return <EuiSkeletonText />
  }

  customRoleOptions = (data?.[projectId]?.roles ?? [])
    .map(
      (role: { name: string; description: string }) =>
        ({
          value: role.name,
          label: role.name,
          toolTipContent: role.description,
          title: '',
        } as EuiComboBoxOptionOption<string>),
    )
    .sort((a: EuiComboBoxOptionOption<string>, b: EuiComboBoxOptionOption<string>) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    )

  const options = getOptions(resourceType, displayCustomRoles, customRoleOptions, roleManagementUrl)

  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiComboBox<string>
          data-test-subj='resourceRoleSelector'
          data-test-id={`resourceRoleSelector-${projectId}`}
          isDisabled={isDisabled}
          placeholder={noRolesPlaceholder(singleSelection)}
          singleSelection={singleSelection}
          options={options}
          selectedOptions={getSelectedOptions(roles, options, customRolesEnabled)}
          onChange={(selectedOptions) => {
            const selectedRoleDescriptors = selectedOptions.map((roleOption) => ({
              roleId: roleOption.value ?? '',
              isCustomRole: !predefinedRoles.find((role) => role.value === roleOption.value),
            })) as TableItemRoleDescriptor[]

            updateTableItemRoles(
              items,
              projectId,
              selectedRoleDescriptors,
              tableItemsToRoleAssignment,
              onChangeRoleAssignments,
            )
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
